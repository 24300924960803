export const policies = [
    {
        to: { name: "who-we-are" },
        title: "home.who",
        isRouteAction: true,
    },

    {
        to: { name: "privacy-policy" },
        title: "home.privacy",
        isRouteAction: true,
    },

    {
        to: { name: "contact-us" },
        title: "home.contactUs",
        isRouteAction: true,
    },

    {
        to: { name: "privacy-use" },
        title: "home.use",
        isRouteAction: true,
    },
];

export const payment = [
    {
        to: { name: "how-to-pay" },
        title: "home.howToPay",
        isRouteAction: true,
    },

    {
        to: { name: "how-to-buy" },
        title: "home.howToBuy",
        isRouteAction: true,
    },

    {
        to: { name: "suggestion" },
        title: "home.suggestion",
        isRouteAction: true,
    },
];
