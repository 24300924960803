<template>
  <div class="base-subscribe black-color-bg p-y-40">
    <div class="container">
      <div class="content white-color-bg p-y-48 p-x-20 radius-24">
        <img
          class="colors-img"
          src="@/assets/images/shared/base-subscribe-img.svg"
          alt=""
        >
        <div class="row">
          <div class="col-lg-3 d-none d-lg-block" />
          <div class="col-lg-5">
            <div class="subscribe-form">
              <div class="title">
                <span class="bold-font dark-gray-color-text m-r-4">{{
                  $t('home.ready')
                }}</span>
                <span class="light-gray-color-text">{{
                  $t('home.subscribe')
                }}</span>
              </div>
              <form @submit.prevent="subscribe">
                <div class="subscribe-input p-4 p-l-6 radius-10 m-y-8">
                  <MailIcon />
                  <input
                    v-model="email"
                    type="email"
                    class="p-y-10 p-r-8 regular-font"
                    :placeholder="$t('forms.email')"
                  >
                  <button
                    type="submit"
                    class="main-color-bg white-color-text medium-font p-y-10 radius-8 btn-main-h"
                  >
                    {{ $t('home.subscribeNow') }}
                  </button>
                </div>

                <div
                  class="gender m-t-10 d-flex justify-content-start align-items-center"
                >
                  <div class="male m-r-20">
                    <label
                      for="male"
                      class="d-flex justify-content-start align-items-center"
                    >
                      <input
                        id="male"
                        v-model="gender"
                        type="radio"
                        value="male"
                        hidden
                      >
                      <span class="radio m-r-8" />
                      <span class="light-gray-color-text medium-font">{{
                        $t('home.male')
                      }}</span>
                    </label>
                  </div>

                  <div class="female">
                    <label for="female">
                      <input
                        id="female"
                        v-model="gender"
                        type="radio"
                        value="female"
                        hidden
                      >
                      <span class="radio m-r-8" />
                      <span class="light-gray-color-text medium-font">{{
                        $t('home.female')
                      }}</span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              class="sms"
              :data-content="$t('home.or')"
            >
              <div class="title">
                <span class="bold-font dark-gray-color-text">{{
                  $t('home.sendSMS')
                }}</span>
              </div>
            </div>

            <div class="sms-subscriber">
              <img
                src="../../../assets/images/home/vodafone.png"
                alt="vodafone"
              >
              <img
                src="../../../assets/images/home/orange.png"
                alt="orange"
                class="mx-2"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from '../../icons/MailIcon'
import isEmailValid from '../../../utils/isValidEmail'

export default {
  name: 'BaseSubscribe',
  components: { MailIcon },
  data () {
    return {
      email: '',
      gender: 'male'
    }
  },
  methods: {
    isEmailValid () {
      return isEmailValid(this.email)
    },
    subscribe () {}
  }
}
</script>

<style lang="scss" scoped>
@import './base-subscribe';
</style>
