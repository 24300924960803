<template>
  <div
    class="base-page-title p-x-16"
    :class="bgColorClass"
  >
    <div class="title">
      <h2
        class="medium-font m-t-16"
        :class="colorClass"
      >
        {{ $t(title) }}
      </h2>
    </div>

    <div class="actions d-flex justify-content-center align-items-center">
      <div
        v-for="(link, index) in links"
        :key="`offers-link-${index}`"
        class="action medium-font d-flex justify-content-center align-items-center"
        :class="[link.routerName === $route.name ? `active ${colorClass}` : '']"
        @click="goTo(link.routerName)"
      >
        <TagIcon
          v-if="link.name === 'العروض الجديدة'"
          class="new-offer-icon"
          :color-class="
            link.routerName === $route.name
              ? svgColorClass
              : 'light-gray-color-fill-svg'
          "
        />
        <FireIcon
          v-else
          :color-class="
            link.routerName === $route.name
              ? svgColorClass
              : 'light-gray-color-fill-svg'
          "
        />

        <span class="m-l-6">{{ $t(link.name) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FireIcon from '../../icons/FireIcon'
import TagIcon from '../../icons/TagIcon'

export default {
  name: 'BasePageTitle',
  components: { FireIcon, TagIcon },
  props: {
    title: {
      type: String,
      required: true
    },

    links: {
      type: Array,
      required: true
    },

    bgColorClass: {
      type: String,
      required: true
    },

    colorClass: {
      type: String,
      required: true
    },

    svgColorClass: {
      type: String,
      required: true
    }
  },
  methods: {
    goTo (routeName) {
      if (routeName !== this.$route.name) {
        this.$router.push({ name: routeName }).catch((err) => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-page-title';
</style>
