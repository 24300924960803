<template>
  <div class="base-layout">
    <!-- <BasePageTitle
            bg-color-class="main-color-bg-with-opacity"
            color-class="main-color-text"
            svg-color-class="main-color-fill-svg"
            :links="['/', '/vouchers/top-offers']"/> -->
    <!-- <div class="navbar d-flex justify-content-start align-items-center ">
            <router-link to="/offers/new-offers">new offers</router-link>
            <router-link to="/offers/best-offers">best offers</router-link>
            <router-link to="/offer/1">offer</router-link>
        </div> -->
    <BaseNavbar />
    <slot />
    <BaseSubscribe />
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from '../components/partials/base-footer/Index.vue'
import BaseNavbar from '../components/partials/base-navbar/Index.vue'
import BaseSubscribe from '../components/partials/base-subscribe/Index.vue'
import BasePageTitle from '../components/partials/base-page-title/Index'

export default {
  name: 'BaseLayout',
  components: { BasePageTitle, BaseSubscribe, BaseFooter, BaseNavbar }
}
</script>

<style lang="scss" scoped>
.base-layout {
  .navbar {
    height: 100px;
    width: 100%;
    background-color: #fff;
    gap: 15px;
  }

  .content {
    background-color: #f6f6f6;
    min-height: calc(100vh - 100px);
  }
}
</style>
