<template>
  <div class="base-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="info">
            <p class="regular-font dark-gray-color-text text-sm-center">
              {{ $t('home.footerText') }}
            </p>
            <div
              class="social-media d-flex justify-content-start align-items-center"
            >
              <a
                href="https://web.facebook.com/Waffarha?_rdc=1&_rdr"
                class="main-color-bg"
                target="_blank"
              >
                <FacebookIcon />
              </a>

              <a
                href="https://www.messenger.com/t/Waffarha"
                class="main-color-bg"
                target="_blank"
              >
                <TwitterIcon />
              </a>

              <a
                href="#"
                class="main-color-bg"
                target="_blank"
              >
                <YoutubeIcon />
              </a>

              <a
                href="#"
                class="main-color-bg"
                target="_blank"
              >
                <MessengerIcon />
              </a>

              <a
                href="#"
                class="main-color-bg"
                target="_blank"
              >
                <WhatsAppIcon />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="about-waffarha text-sm-center">
            <h4 class="medium-font dark-gray-color-text">
              {{ $t('home.about') }}
            </h4>
            <div class="row no-gutters">
              <div
                v-for="(link, i) in policies"
                :key="`navbar-links-${i}`"
                class="col-6"
              >
                <router-link
                  :to="link.to"
                  class="regular-font dark-gray-color-text link-hover"
                >
                  <span>
                    {{ $t(link.title) }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="know-more text-sm-center">
            <h4 class="medium-font dark-gray-color-text">
              {{ $t('home.knowMore') }}
            </h4>
            <div class="row no-gutters">
              <div
                v-for="(link, i) in payment"
                :key="`navbar-links-${i}`"
                class="col-6"
              >
                <router-link
                  :to="link.to"
                  class="regular-font dark-gray-color-text link-hover"
                >
                  <span>
                    {{ $t(link.title) }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookIcon from '../../icons/FacebookIcon'
import TwitterIcon from '../../icons/TwitterIcon'
import YoutubeIcon from '../../icons/YoutubeIcon'
import MessengerIcon from '../../icons/MessengerIcon'
import WhatsAppIcon from '../../icons/WhatsAppIcon'
import { policies, payment } from './data'

export default {
  name: 'BaseFooter',
  components: {
    WhatsAppIcon,
    MessengerIcon,
    YoutubeIcon,
    TwitterIcon,
    FacebookIcon
  },
  data () {
    return {
      policies,
      payment
    }
  }
}
</script>

<style lang="scss" scoped>
.base-footer {
  padding: 40px 0;

  .info {
    margin-bottom: 30px;

    p {
      font-size: 16px;
      line-height: 2;
      width: 90%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .social-media {
      margin-top: 15px;
      flex-direction: row;

      @media (max-width: 767px) {
        justify-content: center !important;
      }

      [dir='rtl'] & {
        flex-direction: row-reverse;
      }

      a {
        width: 40px;
        height: 40px;
        text-decoration: none;
        border-radius: 50%;
        display: grid;
        place-items: center;
        margin-right: 8px;

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 8px;
        }

        svg {
          fill: $white-color;
        }
      }
    }
  }

  .about-waffarha,
  .know-more {
    margin-bottom: 20px;
    h4 {
      font-size: 22px;
      margin-bottom: 20px;
    }

    a {
      display: block;
      margin-bottom: 18px;
      text-decoration: none;
      font-size: 18px;
    }

    .link-hover:hover {
      color: $main-color;
      text-decoration: underline;
      text-decoration-color: #e76300 !important;
    }
  }

  .text-sm-center {
    @media (max-width: 767px) {
      text-align: center;
    }
  }
}
</style>
